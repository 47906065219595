<template>
  <v-container fluid>
    <BackButton :handler="gotoMail"/>
    <v-container class="form_container" fluid no-gutters style="max-width: 95% !important;">
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="d-flex justify-space-between">
          <div class="titles blue-text">Website Notification</div>
          <v-btn
              small
              @click="enableArabic"
              elevation="0"
          >
            <v-icon small>mdi-translate</v-icon>
            AR Lang
          </v-btn>
        </div>
        <v-card class="pb-4 rounded-5 shadow-0">
          <v-card-text class="pb-0">
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <label for="">Title*</label>
                <v-text-field
                    v-model="messageForm.title"
                    :rules="[
                  (v) =>
                    !!v ||
                    'Title is required',
                ]"
                    dense
                    hide-details="auto"
                    outlined
                    required
                    validate-on-blur
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12" v-if="isArabicEnabled">
                <label for="">Ar Title</label>
                <v-text-field
                    v-model="messageForm.ar_title"
                    dense
                    hide-details="auto"
                    outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <label>Message</label>
                <text-editor
                    :message="messageForm.message"
                    @complete="(content)=>messageForm.message = content"
                />
              </v-col>
              <v-col cols="12" md="12" sm="12" v-if="isArabicEnabled">
                <label>Ar Message</label>
                <text-editor
                    :message="messageForm.ar_message"
                    @complete="(content)=>messageForm.ar_message = content"
                />
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <div class="d-flex align-center gap-x-8">
                  <div>
                    <label for="">
                      Enable Online
                    </label>
                    <v-switch
                        v-model="messageForm.is_public"
                        :false-value="0"
                        :true-value="1"
                        class="mx-4 my-0 v-input--reverse"
                        dense
                        hide-details
                    />
                  </div>
                  <div style="width: 200px !important;">
                    <label for="">CTA</label>
                    <v-select
                        v-model="messageForm.cta"
                        :items="ctaTypes"
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                        hide-details="auto"
                        item-text="text"
                        item-value="value"
                        outlined
                        validate-on-blur
                    ></v-select>
                  </div>
                  <template v-if="messageForm.cta === 'link'">
                    <div>
                      <label for="">Link Text*</label>
                      <v-text-field
                          v-model="messageForm.external_text"
                          :rules="[(v) => !!v || 'Link Text is required']"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          outlined
                          required
                          validate-on-blur
                      ></v-text-field>
                    </div>
                    <div>
                      <label for="">Link*</label>
                      <v-text-field
                          v-model="messageForm.external_link"
                          :rules="[(v) => !!v || 'Link is required']"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          outlined
                          required
                          validate-on-blur
                      ></v-text-field>
                    </div>
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div class="titles mt-8 blue-text">Configuration</div>
        <v-card class="rounded-5 shadow-0">
          <v-card-text class="pb-0">
            <v-row>
              <v-col cols="12" md="4" sm="4">
                <label for="">Start Date*</label>
                <date-field
                    v-model="messageForm.start_date"
                    :backFill="
                  checkBackfillPermission($modules.marketing[type].slug)
                "
                    :hide-details="true"
                    :rules="[(v) => !!v || 'Start date is required']"
                    class-name="q-text-field shadow-0"
                    dense
                    label=""
                ></date-field>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <label for="">End Date*</label>
                <date-field
                    v-model="messageForm.end_date"
                    :backFill="
                  checkBackfillPermission($modules.marketing[type].slug)
                "
                    :hide-details="true"
                    :rules="[(v) => !!v || 'End date is required']"
                    class-name="q-text-field shadow-0"
                    dense
                    label=""
                ></date-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <br>
        <v-row class="mt-3">
          <v-btn v-if="id && checkDeletePermission($modules.marketing[type].slug)" class="ma-2 shadow-0 text-white"
                 color="red"
                 @click="deleteNotification">
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-2 white--text teal-color"
              color="darken-1"
              text
              @click="saveMarketingMessage"
          >Save
          </v-btn
          >
        </v-row>
      </v-form>
      <confirm-model
          v-bind="confirmModel"
          @close="confirmModel.id = null"
          @confirm="confirmActions"
      ></confirm-model>
    </v-container>
  </v-container>
</template>

<script>
import TextEditor from "@/components/Marketing/TextEditor";
import {mapGetters} from "vuex";
import BackButton from "@/components/Common/BackButton.vue";

export default {
  data() {
    return {
      id: null,
      messageForm: {
        title: "",
        ar_title: "",
        message: "",
        ar_message: "",
        start_date: null,
        end_date: null,
        is_public: 0,
        cta: null,
        external_text: null,
        external_link: null,
      },
      type: "website_notification",
      emailEditContent: "",
      valid: true,
      ctaTypes: [
        {
          text: "Select CTA",
          value: null
        },
        {
          text: "Link",
          value: "link"
        },
        {
          text: "Book a facility",
          value: "booking"
        }
      ],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      isArabicEnabled:false
    };
  },
  components: {
    BackButton,
    TextEditor,
  },
  mounted() {
    if (typeof this.$route.params.id != "undefined") {
      this.id = atob(this.$route.params.id);
      this.getMessageDetails();
    }
  },
  computed: {
    ...mapGetters({
      checkReadPermission: "checkReadPermission",
    }),
  },
  methods: {
    enableArabic() {
      this.isArabicEnabled = !this.isArabicEnabled;
    },
    saveMarketingMessage() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      const config = {
        method: "post",
        url:
            "venues/marketing/website-notification" +
            (this.id != null ? "/" + this.id : ""),
        data: this.messageForm,
      };
      this.$http(config)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.showSuccess("Saved successfully");
              this.$router.push({
                name: "WebsiteNotification",
              });
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getMessageDetails() {
      this.showLoader("Loading");
      this.$http
          .get("venues/marketing/website-notification/" + this.id)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.messageForm = response.data.data;
              if (this.messageForm.ar_title) {
                this.isArabicEnabled = true;
              }
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    gotoMail() {
      this.$router.push({
        name: "WebsiteNotification",
      });
    },

    deleteNotification() {
      if (!this.id) {
        return;
      }
      this.confirmModel = {
        id: parseInt(this.id),
        title: "Delete notification",
        description: "Are you sure you want to delete this notification?",
        type: 'delete'
      }
    },
    confirmActions(data) {
      if (data.type === 'delete') {
        this.$http
            .delete(`venues/marketing/website-notification/${this.id}`)
            .then((response) => {
              if (response.status === 200 && response.data.status) {
                this.showSuccess(response.data.message);
                this.$router.push({name: "WebsiteNotification"});
              }
            })
            .catch((error) => {
              this.showError(error)
            })
      }
    }
  },
};
</script>

<style></style>
